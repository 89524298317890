<template>

</template>

<script>
import { inject } from "vue"
import { useRouter } from "vue-router"

export default {
  name: "WorkspaceRedirect",
  setup () {
    const activeWorkspace = inject("activeWorkspace")
    const router = useRouter()

    console.log("WorkspaceRedirect")


    if (typeof activeWorkspace.value !== "undefined")
      router.push({
        name: "Dashboard",
        params: {
          workspaceId: activeWorkspace.value.id
        }
      });
    else
      router.push({
        name: "Onboarding"
      })
  }
}
</script>

<style scoped>

</style>